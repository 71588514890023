export default {
	properties: [
		{
			text: '',
			key: '',
			type: 'text',
			is_title: true,
		},
	],
	singular_model_name_spanish: 'Localidad',
	plural_model_name_spanish: 'Localidades',
	create_model_name_spanish: 'Nueva localidad',
	text_delete: 'la',
}