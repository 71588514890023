export default {
	properties: [
		{
			text: 'N°',
			key: 'num',
			type: 'text',
			not_show_on_form: true,
			filter_modal_position: 7,
			filter_type: 'number',
		},
		{
			text: 'Codigo',
			key: 'code',
			type: 'text',
			value: '',
			is_title: true,
		},
		{
			text: 'Cantidad en pesos',
			key: 'amount',
			type: 'number',
			value: null,
			show: true,
			v_if: ['percentage', '=', null],
			v_if_not_check_if_null: true,
		},
		{
			text: 'Porcentaje de descuento',
			key: 'percentage',
			type: 'number',
			value: null,
			show: true,
			v_if: ['amount', '=', null],
			v_if_not_check_if_null: true,
		},
		{
			text: 'Compra minima',
			key: 'min_amount',
			type: 'number',
			value: '',
			show: true,
		},
	],
	singular_model_name_spanish: 'Cupon',
	plural_model_name_spanish: 'Cupones',
	create_model_name_spanish: 'Nuevo cupon',
	text_delete: 'el',
}