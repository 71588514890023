import client from '@/store/chart/client'
export default {
	namespaced: true,
	state: {
	},
	mutations: {
	},
	modules: {
		client,
	},
}
