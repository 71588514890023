export default {
	properties: [
		{
			text: 'Valor',
			key: 'value',
			type: 'text',
			is_title: true,
		},
	],
	singular_model_name_spanish: 'Talle',
	plural_model_name_spanish: 'Talles',
	create_model_name_spanish: 'Nuevo',
	text_delete: 'el',
}